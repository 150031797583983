import { css } from "@emotion/react"
import React, { useContext } from "react"
import PageTitleText from "../../components/atom/PageTitleText"
import ProductItem from "../../components/molecule/ProductItem"
import Layout from "../../components/organism/Layout"
import SEO from "../../components/SEO"
import { globalContext } from "../../contexts/GlobalContext"
import { flexBoxCss } from "../../styles"

const styles = require("./style.module.scss")

const emptyContainer = css`
  ${flexBoxCss("v", "center", "center")}
  margin: 120px 0;

  img {
    width: 150px;
    height: 150px;
    opacity: 0.2;
  }

  span {
    color: #8e8e8e;
  }
`

const searchNameTextStyle = css`
  font-family: "Noto Sans KR";
  font-weight: 700;
  font-size: 23px;  

  @media (max-width: 1024px) {
    font-size: 15px;
    padding-left: 10px;
  }
`

const searchedResultTextStyle = css`
  font-family: "Noto Sans KR";
  font-size: 17px;

  @media (max-width: 1024px) {
    font-size: 14px;  
  }
`


const SearchResult = () => {
  const search = useContext(globalContext)?.searchHook

  return (
    <Layout isSearchPage>
      <SEO title={`검색결과`} />
      <div className={styles["container"]}>
        <span css={searchNameTextStyle}>
          "{search && search.searchedText}"
        </span>
        <span css={searchedResultTextStyle}>
          를 포함한 검색결과
        </span>

        {search?.data.length == 0 ? (
          <div className="v-box" css={emptyContainer}>
            <img src={require("../../images/search.svg")} />
            <span>검색 결과를 찾을 수 없습니다.</span>
          </div>
        ) : (
          <div className={styles["productContainer"]}>
            {search?.data?.map((product, index) => (
              <ProductItem product={product} key={product.id} />
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default SearchResult
